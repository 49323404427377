<template>
  <div class="nutritionalBlock">
    <div>
      <span v-if="suggestedServing">
        Suggested serving size:
        <span class="text-muted serving-size" v-html="suggestedServing"></span>
      </span>
      <hr />
      <ul class="nutritionalList" v-if="data.hasInfo">
        <li v-for="item in data.data" :key="item.label">
          <label class="nutritionalLabel">{{ item.label }}</label
          >{{ !item.text ? '' : ':' }}
          {{ item.text }}
        </li>
      </ul>
      <p v-if="!data.hasInfo">
        {{ data.message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
    suggestedServing: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.nutritionalBlock {
  margin-bottom: rem(10);
}

.nutritionalList {
  margin-top: rem(10);
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 50%;
  }

  .nutritionalLabel {
    font-weight: bold;
  }
}
</style>
