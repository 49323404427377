<template>
  <div id="settings-billing">
    <div class="d-flex flex-column justify-content-center mt-4 mt-md-0">
      <!-- Your Plan -->
      <SettingCard type="rows">
        <template slot="label"> Your Plan </template>
        <template slot="content">
          {{ appManager }}
        </template>
      </SettingCard>
      <!-- Schedule -->
    </div>
  </div>
</template>

<script>
import SettingCard from '@/components/settings/SettingCard.vue'

export default {
  name: 'InAppSubscripiton',
  components: {
    SettingCard,
  },
  props: {
    processor: {
      required: true,
      type: String,
    },
  },
  computed: {
    appManager() {
      if (this.processor === 'apple') {
        return 'Your subscription is managed through Apple. To modify or cancel it, please go to the App Store on your device, tap your profile icon in the top right corner and then tap "Subscriptions".'
      } else {
        return 'Your subscription is managed through Google. To modify or cancel it, open the Google Play Store app, tap your profile icon in the top-right corner, select “Payments & Subscriptions,” and then tap “Subscriptions”.'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.delete {
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 1.125rem;
  line-height: 1.5;
}

.plan-not-editable {
  background-color: $clr-navy-5;
  padding: 1rem;
  text-align: left;
  color: $clr-navy-70;
  font-weight: 500;
}

.the-plan-title {
  font-weight: 500;
  display: block;
}

.card-info-wrapper {
  display: flex;
  align-items: center;
}

.card-info {
  color: $clr-navy-70;
  font-size: 0.875rem;
  padding-left: 0.5rem;
}

.plan-upsell {
  display: block;
  color: $clr-navy-70;
  font-size: 0.875rem;
}

.cancel-plan-title {
  font-size: 20px;
  font-weight: bold;
}

.button-upcase {
  text-transform: uppercase;
}

.action-button {
  padding: 0;
  text-align: right;
  svg {
    width: 28px;
    height: auto;
    vertical-align: bottom;
  }
}
</style>
