import { getMealPlanId, getMealPlanType } from './general'

export const trackDashboardViewMounted = async (
  { rootGetters, dispatch },
  payload
) => {
  const mealPlan = await getMealPlanType({ rootGetters, dispatch })
  const plan_id = getMealPlanId({ rootGetters })

  dispatch('dashboard/setMealPlanTypeName', mealPlan, { root: true })
  return {
    ...payload,
    params: { plan_name: mealPlan, plan_id, ...payload.params },
  }
}

export const getRecipeByMealId = async (context, payload) => {
  const meals = context.rootGetters['dashboard/meals']
  let { recipe } = meals.find((i) => i.id === payload.params.meal_id)
  const npayload = {
    ...payload,
    params: {
      recipe_id: recipe.id,
    },
  }
  return getRecipeById(context, npayload)
}

export const getPlanNameType = (context, payload) => {
  const planName = context.rootGetters['dashboard/getMealPlanTypeName']
  return {
    ...payload,
    params: {
      ...payload.params,
      plan_name: planName,
    },
  }
}

export const getRecipeById = (context, payload) => {
  return {
    ...payload,
    params: {
      ...payload.params,
    },
  }
}

export const getRecipeRatingById = (context, payload) => {
  const recipes = context.rootGetters['RecipeBox/filteredRecipes']
  const recipe = recipes.find(
    (recipe) => recipe.id === payload.params.recipe_id
  )
  return {
    ...payload,
    params: {
      ...payload.params,
      recipe_rating: recipe.rating,
    },
  }
}
